const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location);
    if (!location.pathname.match(/^\/como-pagar\//) || !location.pathname.match(/^\/como-pagar-br\//) ) {
        window.scrollTo(...(currentPosition || [0, 0]));
    }
    return false;
}

export {
    shouldUpdateScroll
}