// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-checkout-br-incognito-not-allowed-tsx": () => import("./../../src/pages/checkout-br/incognito-not-allowed.tsx" /* webpackChunkName: "component---src-pages-checkout-br-incognito-not-allowed-tsx" */),
  "component---src-pages-checkout-br-index-tsx": () => import("./../../src/pages/checkout-br/index.tsx" /* webpackChunkName: "component---src-pages-checkout-br-index-tsx" */),
  "component---src-pages-checkout-br-out-of-stock-tsx": () => import("./../../src/pages/checkout-br/out-of-stock.tsx" /* webpackChunkName: "component---src-pages-checkout-br-out-of-stock-tsx" */),
  "component---src-pages-checkout-br-unexpected-error-tsx": () => import("./../../src/pages/checkout-br/unexpected-error.tsx" /* webpackChunkName: "component---src-pages-checkout-br-unexpected-error-tsx" */),
  "component---src-pages-checkout-co-index-tsx": () => import("./../../src/pages/checkout-co/index.tsx" /* webpackChunkName: "component---src-pages-checkout-co-index-tsx" */),
  "component---src-pages-checkout-co-out-of-stock-tsx": () => import("./../../src/pages/checkout-co/out-of-stock.tsx" /* webpackChunkName: "component---src-pages-checkout-co-out-of-stock-tsx" */),
  "component---src-pages-checkout-co-unauthorized-amount-tsx": () => import("./../../src/pages/checkout-co/unauthorized-amount.tsx" /* webpackChunkName: "component---src-pages-checkout-co-unauthorized-amount-tsx" */),
  "component---src-pages-checkout-co-unexpected-error-tsx": () => import("./../../src/pages/checkout-co/unexpected-error.tsx" /* webpackChunkName: "component---src-pages-checkout-co-unexpected-error-tsx" */),
  "component---src-pages-how-to-pay-br-tsx": () => import("./../../src/pages/how-to-pay-br.tsx" /* webpackChunkName: "component---src-pages-how-to-pay-br-tsx" */),
  "component---src-pages-how-to-pay-tsx": () => import("./../../src/pages/how-to-pay.tsx" /* webpackChunkName: "component---src-pages-how-to-pay-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboarding-widget-tsx": () => import("./../../src/pages/onboarding-widget.tsx" /* webpackChunkName: "component---src-pages-onboarding-widget-tsx" */),
  "component---src-pages-pix-info-tsx": () => import("./../../src/pages/pix-info.tsx" /* webpackChunkName: "component---src-pages-pix-info-tsx" */),
  "component---src-pages-preapproval-br-tsx": () => import("./../../src/pages/preapproval-br.tsx" /* webpackChunkName: "component---src-pages-preapproval-br-tsx" */)
}

